// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minecraft-js": () => import("./../../../src/pages/minecraft.js" /* webpackChunkName: "component---src-pages-minecraft-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-minecraft-place-js": () => import("./../../../src/templates/minecraft-place.js" /* webpackChunkName: "component---src-templates-minecraft-place-js" */),
  "component---src-templates-minecraft-world-js": () => import("./../../../src/templates/minecraft-world.js" /* webpackChunkName: "component---src-templates-minecraft-world-js" */),
  "component---src-templates-review-js": () => import("./../../../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-templates-valheim-place-js": () => import("./../../../src/templates/valheim-place.js" /* webpackChunkName: "component---src-templates-valheim-place-js" */),
  "component---src-templates-valheim-world-js": () => import("./../../../src/templates/valheim-world.js" /* webpackChunkName: "component---src-templates-valheim-world-js" */)
}

